import React from 'react'

const Tech = () => {
  return (
    <div>
      Hi I am Tech
    </div>
  )
}

export default Tech
