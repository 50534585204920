import React, { useState } from "react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import "./index.css";
import Button from "../Button";
import SignUp from "../Modal/SignUpModal";
import SignIn from "../Modal/SignInModal";
import { useNavigate } from "react-router-dom";

const Header = ({ setOpen }) => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("isLoggedIn"); // Fetch user ID from local storage
  const [modal, setModal] = useState(false);
  const [sigUpModal, setSignUpModal] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false); // State to control search visibility

  const onCloseModal = (event) => {
    event.preventDefault();
    setModal((modal) => !modal);
  };

  const onCloseSignUp = (event) => {
    event.preventDefault();
    setSignUpModal((sigUpModal) => !sigUpModal);
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn"); // Remove userId from local storage
    window.location.href = "/";
  };

  const toggleSearch = () => {
    setSearchVisible((prev) => !prev);
  };

  return (
    <>
      <header className="fixed top-0 w-full z-50 flex items-center bg-white px-4 py-2 shadow-sm">
        <a href="/" className="relative h-10 w-16">
          <img
            src="https://discusswerk.com/assets/discuss-werk-bg-pDwXhT9h.webp"
            alt="Logo"
            style={{ borderRadius: 10 }}
          />
        </a>

        <div className="ml-auto flex items-center lg:hidden">
          <MagnifyingGlassIcon
            className="h-8 w-8 text-black"
            aria-hidden="true"
            onClick={toggleSearch}
          />
        </div>

        <form
          className={`flex items-center rounded-lg border border-gray-200 bg-gray-100 px-3 py-3 ml-5 transition-all duration-300 ${
            isSearchVisible ? 'block' : 'hidden'
          } lg:flex`}
        >
          <MagnifyingGlassIcon
            className="h-6 w-6 text-gray-400"
            aria-hidden="true"
            onClick={toggleSearch}

          />
          <input
            type="text"
            placeholder="Search Results"
            name="search"
            className="flex-1 bg-transparent outline-none"
            aria-label="Search"
            style={{ borderRadius: 15 }}
          />
          <button type="submit" className="hidden" />
        </form>

        <div className="ml-auto flex items-center lg:hidden" onClick={() => setOpen((prev) => !prev)}>
          <Bars3Icon className="h-8 w-10 text-black" aria-hidden="true" />
        </div>
        
        <div className="hidden lg:flex gap-2 ml-auto">
          {userId ? (
            <Button text={"Logout"} onClick={handleLogout}  />
          ) : (
            <>
              <Button text={"Sign In"} onClick={onCloseModal} />
              <Button text={"Sign Up"} onClick={onCloseSignUp} />
            </>
          )}
        </div>
      </header>
      {modal && <SignIn onCloseModal={onCloseModal} />}
      {sigUpModal && <SignUp onClose={onCloseSignUp} />}
    </>
  );
};

export default Header;
