import React from 'react'

const Eye = () => {
  return (
    <div >
<svg xmlns="http://www.w3.org/2000/svg" className='my-2 h-4 w-4' fill="none" viewBox="0 0 20 20" class="my-2 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M3.4 10c0-.741.626-1.879 2.015-2.929C6.748 6.064 8.46 5.4 10 5.4c1.54 0 3.252.664 4.585 1.671C15.974 8.121 16.6 9.26 16.6 10c0 .741-.626 1.879-2.015 2.928C13.252 13.936 11.54 14.6 10 14.6c-1.54 0-3.252-.664-4.585-1.671C4.026 11.878 3.4 10.74 3.4 10ZM10 4c-3.81 0-8 3.212-8 6s4.19 6 8 6c3.81 0 8-3.212 8-6s-4.19-6-8-6Zm-1.3 6a1.3 1.3 0 1 1 2.6 0 1.3 1.3 0 0 1-2.6 0ZM10 7.3a2.7 2.7 0 1 0 0 5.4 2.7 2.7 0 0 0 0-5.4Z" clip-rule="evenodd"></path></svg>    </div>
  )
}

export default Eye
