import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Sidebar from "./componets/Drawer";
import Header from "./componets/Header";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tech from "./componets/Tech";
import Feed from "./componets/Feed";
import CreatePost from "./componets/CreatePost";
import DetailComponent from "./componets/DetailComponent";
import Blog from "./componets/Blog";
import Reviews from "./componets/Reviews";
import ForgotPassword from "./componets/Modal/ForgetPassword";
import { useTheme } from "@emotion/react";
import { Hidden } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000", // Black
    },
    secondary: {
      main: "#ffffff", // White
    },
  },
  typography: {
    // You can customize the typography here
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 18,
      color: "#ffffff", // White for secondary text
    },
    // Add other custom typography as needed
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container />
    </ThemeProvider>
  );
}

function Container() {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <div className="App">
      <Router>
        <Header setOpen={setOpen} />
        <Sidebar open={open} setOpen={setOpen} />
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="search/:query" element={<Feed />} />
          <Route path="tech" element={<Tech />} />
          <Route path="createPost" element={<CreatePost />} />
          <Route path="DetailComponent" element={<DetailComponent />} />
          <Route path="Blog" element={<Blog />} />
          <Route path="Reviews" element={<Reviews />} />
          <Route path="forget-password" element={<ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
