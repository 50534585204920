import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import React from 'react'

const Reviews = () => {
  return (
    <div className='flex w-full justify-center flex-1'>
    <div className='flex w-full flex-col items-center gap-10 py-6 lg:py-20'>
     <div className='h-fit w-full max-w-[1432px] px-4'>

<div className='flex flex-col items-center gap-3 px-5 text-center lg:px-0 lg:text-left'>
<h1 className='text-2xl'>Anonymous and Verified Employee Reviews — No Names Needed</h1>
<p className='text-lg'>
Discover how employees rate and review their company!
</p>
<form className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-3">
        <MagnifyingGlassIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
        <input type="text" placeholder="Search for the company" className="flex-1 bg-transparent outline-none " aria-label="Search" />
        <button type="submit" className="hidden" />
      </form>
</div>

     </div>
     <div className='mt-[60px] flex w-full justify-center bg-blue-300'>
      <div className='flex w-full justify-center'>
    <div className='h-fit max-w-[1432px] flex w-max flex-col-reverse px-6 lg:flex-row lg:gap-12 lg:px-20' >
 <div className='class="flex w-full flex-col gap-5 py-5 text-center lg:w-[270px] lg:text-left"'>
    <p className='text-xl'>
    Review your company!
    </p>
    <p className='text-gray-700'>
    Your honest responses will help other job seekers and it's anonymous!
    </p>
    <button class="inline-flex items-center justify-center rounded-lg text-sm font-semibold transition-colors disabled:opacity-50 disabled:cursor-not-allowed text-white h-10 px-4 bg-blue-system hover:bg-blue-900 active:bg-blue-system-pressing">Review your company</button>
 </div>
    </div>
      </div>
     </div>
    </div>
      
    </div>
  )
}

export default Reviews
