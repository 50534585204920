import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useLocation } from 'react-router-dom';

const CreatePost = () => {

  const location = useLocation();
  const updateData = location?.state?.updateData;

  const communities = [
    { id: 1, name: 'Tech Enthusiasts' },
    { id: 2, name: 'Music Lovers' },
    { id: 3, name: 'Book Readers' },
    { id: 4, name: 'Fitness Fans' },
  ];
  const categories = [
    { id: 1, name: "Ride Share Hub" },
    { id: 2, name: "Delivery Squad Hub" },
    { id: 3, name: "Hospitality Hub" },
  ];
  const states = [
    { id: 1, name: 'Alabama' },
    { id: 2, name: 'New York' },
    { id: 3, name: 'North Carolina' },
    { id: 4, name: 'Virgin Islands, U.S.' },
    { id: 5, name: 'District of Columbia' },
    { id: 6, name: 'Texas' },
  ];

  const getActiveTab = () => {
    if (updateData) {
      if (updateData.Link === '0' && updateData.Image === '0') {
        return 'text';
      } else if (updateData.Link === '0') {
        return 'image_video';
      }
      return 'link';
    } else {
      return 'text';
    }
  }


  const [selectedCommunity, setSelectedCommunity] = useState(updateData ? updateData.com_id : '');
  const [selectedCategories, setSelectedCategories] = useState(updateData ? updateData.Categories : '');
  const [selectedState, setSelectedState] = useState(updateData ? updateData.location : '');
  const [activeTab, setActiveTab] = useState(getActiveTab());
  const [title, setTitle] = useState(updateData ? updateData.title : '');
  const [content, setContent] = useState(updateData ? updateData.description : '');
  console.log("content", content)
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState(updateData ? updateData.Link : '');
  const [communityData, setCommunityData] = useState("");
  const [errorLoaded, setErrorLoaded] = useState('');
  const [userNameData, setUserData] = useState("")
  console.log("userNameData", userNameData)
  const userId = localStorage.getItem('userId'); // Fetch user ID from local storage
  console.log("selectedState", selectedState)
  console.log("selectedCategories", selectedCategories)
  console.log("selectedCommunity", selectedCommunity)
  const selectedCategoryName = categories.find(category => category.id.toString() === selectedCategories)?.name || '';
  // Retrieve the selected state name
  const selectedStateName = states.find(state => state.id.toString() === selectedState)?.name || '';
  console.log("selectedCategoryName", selectedCategoryName)
  console.log("selectedStateName", selectedStateName)

  useEffect(() => {
    getCommunityData(userId);
  }, []);


  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await getUser(userId);
      console.log("userData", userData)

      if (userData) {
        setUserData(userData); // Assuming the user data has a 'name' field
      }
    };

    fetchUserData();
  }, [userId]); // Re-fetch if userId changes

  const getCommunityData = async (userId) => {
    const apiUrl = 'https://livechat.discusswerk.com/community/getCommunity'; // Replace with your actual API endpoint

    try {
      const response = await fetch(`${apiUrl}?userId=${userId}`, { // Assuming userId is passed as a query parameter
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authToken}`, // Uncomment if using token-based auth
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data", data)
        // Adding an id to each community item
        const dataWithIds = data?.communityName.map((item, index) => ({
          ...item,
          id: index + 1 // Assigning a unique id starting from 1
        }));
        console.log('Community data fetched successfully:', dataWithIds);
        // const data = await response.json();
        // console.log('Community data fetched successfully:', data);
        setCommunityData(dataWithIds);
      } else {
        const errorData = await response.text(); // Fetch error message as text or json as per your API response
        setErrorLoaded(errorData);
        console.error('Error response from server:', errorData);
      }
    } catch (error) {
      setErrorLoaded('Network error while fetching community data');
      console.error('Network error while fetching community data:', error);
    }
  };
  const [files, setFiles] = useState(null);
  console.log("files", files)
  // Handler for file changes
  const handleFileChange = (event) => {
    // Update state with the new files
    setFiles(event.target.files[0]);
  };
  const getUser = async (userId) => {
    const apiUrl = 'https://livechat.discusswerk.com/users/getUser'; // Replace with your actual API endpoint

    try {
      const response = await fetch(`${apiUrl}?userId=${userId}`, { // Assuming userId is passed as a query parameter
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authToken}`, // Uncomment if using token-based auth
        }
      });

      if (response.ok) {
        const data = await response.json();
        return data.name;
        console.log("data", data)
        // Adding an id to each community item

        // const data = await response.json();
        // console.log('Community data fetched successfully:', data);

      } else {
        const errorData = await response.text(); // Fetch error message as text or json as per your API response
        setErrorLoaded(errorData);
        console.error('Error response from server:', errorData);
      }
    } catch (error) {
      setErrorLoaded('Network error while fetching community data');
      console.error('Network error while fetching community data:', error);
    }
  };
  const handleCommunityChange = (event) => {
    setSelectedCommunity(event.target.value);
  };
  const handleCategoriesChange = (event) => {
    setSelectedCategories(event.target.value);
  };
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };


  const handleTitleChange = (event) => {
    if (event.target.value.length <= 300) {
      setTitle(event.target.value);
    }
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const [options, setOptions] = useState(['']);

  const handleOptionChange = (event, index) => {
    const newOptions = [...options];
    newOptions[index] = event.target.value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, '']);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const submitPoll = () => {
    // Implement your submit logic here...
    // For example, you could send the title and options to your backend
    console.log('Poll Question:', title);
    console.log('Poll Options:', options);
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();
    // Append file data if available
    if (files) {
      formData.append('Image', files ? files : "0");
    }
    // Append all other form data
    formData.append('userId', userId);
    formData.append('userNameData', userNameData);
    formData.append('com_id', selectedCommunity);
    formData.append('Categories', selectedCategories);
    formData.append('location', selectedState);
    formData.append('categoryName', selectedCategoryName);
    formData.append('selectedStateName', selectedStateName);
    formData.append('title', title ? title : "0");
    formData.append('description', content ? content : "0");
    formData.append('Link', link ? link : "0");

    try {
      const response = await fetch('https://livechat.discusswerk.com/posts/addPost', {
        method: 'POST',
        body: formData,  // Use FormData for the request body
      });
      if (!response.ok) throw new Error('Failed to submit post');
      alert('Post submitted successfully!');
    } catch (err) {
      alert('Failed to submit post: ' + err.message);
      console.error(err);
    } finally {
      setLoading(false)
    }

  };

  const handleUpdate = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();
    // Append file data if available
    if (files) {
      formData.append('Image', files ? files : "0");
    }
    formData.append('id', updateData.id);
    // Append all other form data
    formData.append('userId', userId);
    formData.append('userNameData', userNameData);
    formData.append('com_id', selectedCommunity);
    formData.append('Categories', selectedCategories);
    formData.append('location', selectedState);
    formData.append('categoryName', selectedCategoryName);
    formData.append('selectedStateName', selectedStateName);
    formData.append('title', title ? title : "0");
    formData.append('description', content ? content : "0");
    formData.append('Link', link ? link : "0");

    try {
      const response = await fetch('https://livechat.discusswerk.com/posts/updatePost', {
        method: 'POST',
        body: formData,  // Use FormData for the request body
      });
      if (!response.ok) throw new Error('Failed to submit post');
      alert('Post updated successfully!');
    } catch (err) {
      alert('Failed to submit post: ' + err.message);
      console.error(err);
    } finally {
      setLoading(false)
    }

  }

  return (
    <div   className='responsive-div w-100 mx-auto pb-xl col-start-1 col-end-1 row-start-1 row-end-auto m:col-start-4 xs:col-end-9 m:col-end-11 l:col-start-4 l:col-end-13 xl:col-start-5 xl:col-end-14' >
      <h1 className='mb-md  text-neutral-content font-bold ml-md'>
        Create post
      </h1> 
      {
        communityData &&
        <>
          <label htmlFor="community-select" className="block text-md font-medium text-neutral-content">
            Select Community
          </label>
          <select
            id="community-select"
            className="mt-1 mb-4 cursor-pointer block w-full pl-3 pr-10 py-4 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-gray-100"
            value={selectedCommunity}
            onChange={handleCommunityChange}
          >
            <option value="">Please select a community...</option>
            {communityData?.map((community) => (
              <option key={community.id} value={community.id}>
                {community.communityName}
              </option>
            ))}
          </select>
        </>

      }


      <label htmlFor="community-select" className="block text-md font-medium text-neutral-content">
        Select Categories
      </label>
      <select
        id="community-select"
        className="mt-1 mb-4 cursor-pointer block w-full pl-3 pr-10 py-4 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-gray-100"
        value={selectedCategories}
        onChange={handleCategoriesChange}
      >
        <option value="">Please select a Categories...</option>
        {categories.map((community) => (
          <option key={community.id} value={community.id}>
            {community.name}
          </option>
        ))}
      </select>
      <label htmlFor="community-select" className="block text-md font-medium text-neutral-content">
        Select States
      </label>
      <select
        id="community-select"
        className="mt-1 mb-4 cursor-pointer block w-full pl-3 pr-10 py-4 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-gray-100"
        value={selectedState}
        onChange={handleStateChange}
      >
        <option value="">Please select a States...</option>
        {states.map((community) => (
          <option key={community.id} value={community.id}>
            {community.name}
          </option>
        ))}
      </select>
      {/* Tab Selection */}
      <div className="flex border-b">
        {['text', 'image_video', 'link'].map(type => (
          <button
            key={type}
            onClick={() => setActiveTab(type)}
            className={`py-2 px-4 text-sm focus:outline-none ${activeTab === type ? 'border-b-2 border-indigo-500 text-indigo-500' : 'border-transparent text-gray-500'}`}
          >
            {type.replace('_', ' & ').toUpperCase()}
          </button>
        ))}
      </div>


      {/* Content based on Tab selection */}
      {activeTab === 'text' && (
        <>
          <div className="mt-4">
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter title here..."
              className="w-full p-2 border rounded-md"
            />
            <div className="text-right text-sm">{title.length}/300 words</div>
          </div>
          <div className="mt-4">
            <Editor
              apiKey="knzj33zddmu4171ye6ljr5if0bzpt8hp7she383kujrgn71j"
              value={content}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={handleEditorChange}
            />
          </div>
        </>
      )}
      {activeTab === 'image_video' && (
        <>
          <div className="mt-4">
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter title here..."
              className="w-full p-2 border rounded-md"
            />
            <div className="text-right text-sm">{title.length}/300 words</div>
          </div>

          {!loading && (
            <div className="mt-4 flex w-full border-sm rounded-md overflow-hidden group min-h-[min(20vw,150px)] border-neutral-border border-dashed">
              <input
                type="file"
                accept="image/*, video/*"
                style={{ display: 'none' }}
                id="file-upload"
                disabled={files}
                onChange={handleFileChange} // Add the onChange handler here
              />
              <label htmlFor="file-upload" className="w-full p-2 flex items-center justify-center border rounded-md text-center bg-gray-100 cursor-pointer">
                {files ? files?.name : 'Upload Image/Video'
                }
              </label>
            </div>
          )}
        </>

      )}
      {activeTab === 'link' && (
        <>

          <div className="mt-4">
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter title here..."
              className="w-full p-2 border rounded-md"
            />
            <div className="text-right text-sm">{title.length}/300 words</div>
          </div>
          <div className="mt-4">
            <input
              type="text"
              value={link}
              onChange={handleLinkChange}
              placeholder="Enter link here..."
              className="w-full p-2 border rounded-md"
            />
          </div>
        </>
      )}

      {/* {activeTab === 'poll' && (
        <div className="mt-4">
          <label htmlFor="poll-question" className="block text-md font-medium text-neutral-content mb-2">
            Poll Question
          </label>
          <input
            type="text"
            id="poll-question"
            placeholder="What do you want to ask?"
            className="w-full p-2 border rounded-md mb-4"
            value={title}
            onChange={handleTitleChange}
          />

          {options.map((option, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                placeholder={`Option ${index + 1}`}
                className="w-full p-2 border rounded-md mr-2"
                value={option}
                onChange={(e) => handleOptionChange(e, index)}
              />
              <button
                type="button"
                onClick={() => removeOption(index)}
                className="bg-red-500 text-white px-2 py-1 rounded-md"
              >
                X
              </button>
            </div>
          ))}
          <div className='mt-10'>

            <button
              type="button"
              onClick={addOption}
              className="bg-indigo-500 text-white px-4 py-2 rounded-md mb-4 mr-3"
            >
              Add Option
            </button>

            <button
              type="button"
              onClick={submitPoll}
              className="bg-green-500 text-white px-4 py-2 rounded-md"
            >
              Submit Poll
            </button>
          </div>
        </div>
      )} */}
      {loading && <p>Loading...</p>}


      <div className="mt-4" onClick={(e) => {
        updateData ? handleUpdate(e) : handleSubmit(e)
      }}>
        <button className="bg-indigo-500 text-white px-4 py-2 rounded-md" disabled={loading} >
          {updateData ? "Update Post" : "Submit Post"}
        </button>
      </div>
    </div>
  );
};

export default CreatePost;
