import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DevicesIcon from "@mui/icons-material/Devices";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WorkIcon from "@mui/icons-material/Work";
import SupportIcon from "@mui/icons-material/Support";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import FunnyIcon from "@mui/icons-material/SentimentVerySatisfied";
import ReviewIcon from "@mui/icons-material/RateReview";
import RecipeIcon from "@mui/icons-material/RestaurantMenu";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import PeopleIcon from "@mui/icons-material/People";
import DescriptionIcon from "@mui/icons-material/Description";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RateReviewIcon from "@mui/icons-material/RateReview";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ModalLocation from "../Modal/index.js";
import "./index.css";
import Feed from "../Feed/index.js";

function Sidebar({ open, setOpen }) {
  const [communityOpen, setCommunityOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [communityName, setCommunityName] = useState("");
  const [error, setError] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});
  const [openLoginSection, setOpenLoginSection] = useState(false);
  const [communityData, setCommunityData] = useState(null);
  const [errorLoaded, setErrorLoaded] = useState("");
  const [activeItem, setActiveItem] = useState("");

  console.log("communityData", communityData);
  const userId = localStorage.getItem("userId"); // Fetch user ID from local storage
  const navigate = useNavigate();

  useEffect(() => {
    getCommunityData(userId);
  }, []);

  const getCommunityData = async (userId) => {
    const apiUrl = "https://livechat.discusswerk.com/community/getCommunity"; // Replace with your actual API endpoint

    try {
      const response = await fetch(`${apiUrl}?userId=${userId}`, {
        // Assuming userId is passed as a query parameter
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${authToken}`, // Uncomment if using token-based auth
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Community data fetched successfully:", data);
        setCommunityData(data.communityName);
      } else {
        const errorData = await response.text(); // Fetch error message as text or json as per your API response
        setErrorLoaded(errorData);
        console.error("Error response from server:", errorData);
      }
    } catch (error) {
      setErrorLoaded("Network error while fetching community data");
      console.error("Network error while fetching community data:", error);
    }
  };
  const handleNavigation = (data) => {
    navigate("/", { state: { customProp: { value: data.id, type: "com" } } });
  };
  const handleSubMenuClick = (index) => {
    setOpenSubMenus((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const toggleLoginSeclection = () => {
    setOpenLoginSection(!open);
  };

  const toggleCommunity = () => {
    if (!userId) {
      setOpenLoginSection(true);
    } else {
      setCommunityOpen(!communityOpen);
    }
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 'unset',  // Unset width for small screens
      sm: 500,  // 500px width for medium and larger screens
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: {
      xs: 2,  // 20px padding for small screens
      sm: 4,  // Default padding for medium and larger screens
    },
    outline: "none",
  };
  const location = useLocation();
  const menuItems = [{ name: "Feed", icon: <HomeIcon />, path: "/" }];
  const categories = [
    {
      name: "Categories",
      icon: <MenuIcon />,
      children: [
        {
          name: "Tips and Tricks",
          icon: <WorkIcon />,
          path: "/",
          customProp: { value: "Tips and Tricks", type: "Categories" },
        },
        {
          name: "Job Openings",
          icon: <LightbulbIcon />,
          path: "/",
          customProp: { value: "Job Openings", type: "Categories" },
        },
        {
          name: "Gear Reviews",
          icon: <ReviewIcon />,
          path: "/",
          customProp: { value: "Gear Reviews", type: "Categories" },
        },
        {
          name: "Venting/Support",
          icon: <SupportIcon />,
          path: "/",
          customProp: { value: "Venting/Support", type: "Categories" },
        },
        {
          name: "Industry News",
          icon: <AnnouncementIcon />,
          path: "/",
          customProp: { value: "Industry News", type: "Categories" },
        },
        {
          name: "Recipe Exchange",
          icon: <RecipeIcon />,
          path: "/",
          customProp: { value: "Recipe Exchange", type: "Categories" },
        },
        {
          name: "Funny Stories",
          icon: <FunnyIcon />,
          path: "/",
          customProp: { value: "Funny Stories", type: "Categories" },
        },
      ],
    },
    // {
    //   name: 'Blog', icon: <DescriptionIcon />, children: [
    //     { name: 'Work', path: '/', customProp: { value: "Work", type: "Blog" } },
    //   ]
    // },
    {
      name: "Locations",
      icon: <LocationCityIcon />,
      children: [
        {
          name: "New York",
          path: "/",
          customProp: { value: "New York", type: "Location" },
        },
        {
          name: "London",
          path: "/",
          customProp: { value: "London", type: "Location" },
        },
        {
          name: "North Carolina",
          path: "/",
          customProp: { value: "North Carolina", type: "Location" },
        },
      ],
    },
    {
      name: "My Post",
      icon: <RateReviewIcon />,
      path: "/",
      customProp: { value: userId, type: "user" },
    },
  ];
  const handleClick = (item, depth, index) => {
    if (item.children) {
      handleSubMenuClick(`${depth}-${index}`);
    } else if (item.path) {
      navigate(item.path, { state: { customProp: item.customProp } });
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setError(false); // Reset error state when closing
  };

  const handleCommunityNameChange = (event) => {
    setCommunityName(event.target.value);
    // if (error) setError(false);
  };
  const handleCreateCommunity = async () => {
    const communityNameTrimmed = communityName.trim();
    // if (!communityNameTrimmed) {
    //   setError(true);
    //   return;
    // }

    // Define the API endpoint URL
    const apiUrl = "https://livechat.discusswerk.com/community/community"; // Replace with your actual API endpoint

    // Use fetch to POST the data to the server
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // If you're using a token for authorization, include it in the headers
          // 'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId: userId,
          communityName: communityNameTrimmed,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Community created successfully:", data);
        // Handle any post-creation logic here (e.g., close modal, reset state)
        setCommunityName("");
        getCommunityData(userId);
        handleCloseModal();
      } else {
        const errorData = await response.json();
        // Handle HTTP errors here
        console.error("Error response from server:", errorData);
        setError(true); // Set an appropriate error state/message
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error while creating community:", error);
    }
  };
  // Function to determine if the item is the active route
  const isActive = (path) => location.pathname === path;
  const renderListItems = (items, depth = 0) => {
    return items.map((item, index) => (
      <React.Fragment key={`${item.name}-${index}`}>
        <ListItem disablePadding sx={{ pl: depth * 4 }}>
          <ListItemButton onClick={() => handleClick(item, depth, index)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
            {item.children &&
              (openSubMenus[`${depth}-${index}`] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              ))}
          </ListItemButton>
        </ListItem>
        {item.children && (
          <Collapse
            in={openSubMenus[`${depth}-${index}`] || false}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {renderListItems(item.children, depth + 1)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };
  return (
    <div className="side-bar">
      <IconButton
        onClick={toggleDrawer}
        sx={{ marginLeft: "auto", color: "white" }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        variant="persistent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            marginTop: {
              xs: "70px",  // 70px for small screens
              sm: "100px",  // 100px for medium and larger screens
            },
            maxWidth: "calc(1690px + 240px)",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton component={Link} to={item.path} onClick={() => {
                if (item.path === "/") {
                  window.location.reload();
                }
              }} >
                <ListItemIcon
                  sx={{
                    color: isActive(item.path) ? "black" : "gray",
                    fontSize: isActive(item.path) ? "2rem" : "1.5rem",
                  }}
                >
                  {React.cloneElement(item.icon, {
                    style: {
                      fontSize: isActive(item.path) ? "2rem" : "1.5rem",
                    },
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    fontWeight: isActive(item.path) ? "bold" : "normal",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={toggleCommunity}>
              <ListItemIcon sx={{ color: "gray", fontSize: "1.5rem" }}>
                <PeopleIcon style={{ fontSize: "1.5rem" }} />
              </ListItemIcon>
              <ListItemText
                primary="Community"
                primaryTypographyProps={{
                  fontWeight: communityOpen ? "bold" : "normal",
                }}
              />
              {communityOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={communityOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 2 }} onClick={handleOpenModal}>
                <ListItemIcon sx={{ color: "gray", fontSize: "1.5rem" }}>
                  <AddCircleOutlineIcon style={{ fontSize: "1.5rem" }} />
                </ListItemIcon>
                <ListItemText primary="Add Community" />
              </ListItemButton>
              {communityData?.map((data, index) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={index}
                  onClick={() => handleNavigation(data)}
                >
                  <ListItemText primary={data.communityName} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Divider />

          <List>{renderListItems(categories)}</List>
        </List>
      </Drawer>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="create-community-modal"
        aria-describedby="create-community-description"
      >
        <Box sx={modalStyle}>
          <IconButton onClick={handleCloseModal} sx={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
          <Typography id="create-community-modal" variant="h6" component="h2">
            Create a Community
          </Typography>
          <Typography id="create-community-description" sx={{ mt: 2 }}>
            Build and grow a community about something you care about. We'll
            help you set things up.
          </Typography>
          <TextField
            fullWidth
            label="Community Name"
            className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-1"
            value={communityName}
            onChange={handleCommunityNameChange}
            // error={error}
            // helperText={error ? "Community name cannot be empty." : ""}
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <button className="modal-button" onClick={handleCreateCommunity}>
              Create Community
            </button>
          </Box>
        </Box>
      </Modal>
      {openLoginSection && <ModalLocation />}
    </div>
  );
}

export default Sidebar;
