import React from 'react'
import "./index.css"
const Blog = () => {
  return (
    <div className='main'>
      <div className='text'>
<h3>
    Latest
</h3>
      </div>
      <div className='detail-content'>
       <img src='https://www.teamblind.com/blog/content/images/size/w1200/2023/10/Situational-Interview.jpg'/>
    <div className='detail-content-text'>
  <h1>
  How to Answer Situational Interview Questions in a Tech Interview

  </h1>
  <p>
  If you’re interviewing with a tech company, you’ll likely be asked situational interview questions. Learning how to effectively answer them can mean the difference between landing the role and receiving the dreaded “We’ve moved forward with more qualified applicants” email.
What is the purpose of a situational
  </p>
    </div>
      </div>
      <div className='detail-content'>
    <div className='detail-content-text'>
  <h1>
  Blind CEO Approval Rating: The Most Popular CEOs, According to Employees


  </h1>
  <p>
  If you’re interviewing with a tech company, you’ll likely be asked situational interview questions. Learning how to effectively answer them can mean the difference between landing the role and receiving the dreaded “We’ve moved forward with more qualified applicants” email.
What is the purpose of a situational
  </p>
    </div>
    <img src='https://www.teamblind.com/blog/content/images/size/w1200/2023/10/Business-Newspaper.jpg'/>

      </div>
    </div>
  )
}

export default Blog
