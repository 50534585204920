import React, { useState, useEffect } from "react";
import { EyeIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Typography } from "@mui/material";
import "./index.css";
import Dot from "../../assets/dot";
import View from "../../assets/view";
import Comment from "../../assets/comment";
import Eye from "../../assets/eye";
import ModalLocation from "../Modal";
import { Link, useParams } from "react-router-dom";
import DetailComponent from "../DetailComponent";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";

const userId = localStorage.getItem("isLoggedIn"); // Fetch user ID from local storage

const Feed = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customProp = location.state?.customProp;
  const navigate = useNavigate();
  const placeHolder = userId ? "Create a Post" : "SignIn to post";
  const [Detail, setDetail] = useState(false);
  console.log("customProp", location);
  const HtmlContent = ({ html }) => {
    return (
      <p
        className="whitespace-pre-wrap break-words text-sm/5 [word-break:break-word] self-start"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  const [errorLoaded, setErrorLoaded] = useState("");
  const [feedData, setFeedData] = useState(null);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [query, setQuery] = useState(queryParams.get("search"));

  useEffect(() => {
    if (location.pathname === "/") {
      setQuery(null)
    }
  }, [location])

  const handleNavigation = () => {
    if (userId === "1") {
      navigate("/createPost");
    } else {
      // Replace this with the function that opens the modal
      setDetail(true);
    }
  };

  useEffect(() => {
    if (customProp) {
      getPostDataQuery();
    } else {
      getPostData();
    }
  }, [query]);
  console.log("query", query);
  useEffect(() => {
    getPostDataQuery();
  }, [customProp, query]);

  const handleSearch = () => {};
  const getPostData = async () => {
    if (query) {
      const response = await fetch(
        `https://livechat.discusswerk.com/posts/searchPost?query=${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setFeedData(data.result);
      return;
    }
    const apiUrl = "https://livechat.discusswerk.com/posts/getPost"; // Replace with your actual API endpoint

    try {
      const response = await fetch(`${apiUrl}`, {
        // Assuming userId is passed as a query parameter
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${authToken}`, // Uncomment if using token-based auth
        },
      });

      if (response.ok) {
        const data = await response.json();
        setFeedData(data.post);
      } else {
        const errorData = await response.text(); // Fetch error message as text or json as per your API response
        setErrorLoaded(errorData);
        console.error("Error response from server:", errorData);
      }
    } catch (error) {
      setErrorLoaded("Network error while fetching community data");
      console.error("Network error while fetching community data:", error);
    }
  };
  const getPostDataQuery = async () => {
    if (!query) {
      if (userId === "1") {
        let apiUrl = "https://livechat.discusswerk.com/posts/getPostQuery";
        if (customProp?.type === "Categories") {
          apiUrl += `?categoryName=${encodeURIComponent(
            customProp.value
          )}&selectedStateName=${encodeURIComponent(
            ""
          )}&com_id=${encodeURIComponent("")}&user_id=${encodeURIComponent(
            ""
          )}`;
        } else if (customProp?.type === "Location") {
          apiUrl += `?categoryName=${encodeURIComponent(
            ""
          )}&selectedStateName=${encodeURIComponent(
            customProp.value
          )}&com_id=${encodeURIComponent("")}&user_id=${encodeURIComponent(
            ""
          )}`;
        } else if (customProp?.type === "com") {
          apiUrl += `?categoryName=${encodeURIComponent(
            ""
          )}&selectedStateName=${encodeURIComponent(
            ""
          )}&com_id=${encodeURIComponent(
            customProp.value
          )}&user_id=${encodeURIComponent("")}`;
        } else if (customProp?.type === "user") {
          apiUrl += `?categoryName=${encodeURIComponent(
            ""
          )}&selectedStateName=${encodeURIComponent(
            ""
          )}&com_id=${encodeURIComponent("")}&user_id=${encodeURIComponent(
            customProp.value
          )}`;
        }

        try {
          const response = await fetch(`${apiUrl}`, {
            // Assuming userId is passed as a query parameter
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // 'Authorization': `Bearer ${authToken}`, // Uncomment if using token-based auth
            },
          });
          if (response.ok) {
            const data = await response.json();
            console.log("data", data);
            setFeedData(data);
          } else {
            const errorData = await response.text(); // Fetch error message as text or json as per your API response
            setErrorLoaded(errorData);
            setFeedData([]);
            console.error("Error response from server:", errorData);
          }
        } catch (error) {
          setErrorLoaded("Network error while fetching community data");
          console.error("Network error while fetching community data:", error);
        }
      } else {
        setDetail(true);
      }
    }
  };
  const DetailComponentFunction = (data) => {
    navigate("/DetailComponent", { state: data });
  };

  const updateCount = async (postId, type) => {
    const likedPosts = localStorage.getItem("liked_posts") || [];
    if (likedPosts.includes(postId)) {
      return;
    }
    setLoading(true);
    const apiUrl = `https://livechat.discusswerk.com/posts/updatePostCount`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postId, type }),
      });
      if (!response.ok) {
        throw new Error("Failed to update count");
      }
      localStorage.setItem("liked_posts", [...likedPosts, postId]);
      await getPostData(); // Re-fetch posts to update the UI
      setLoading(false);
    } catch (error) {
      console.error("Error updating count:", error);
    }
  };
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal((modal) => !modal);
  };
  const embedYouTubeVideo = (link) => {
    const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
    const match = link.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    setDeleteLoading(true);
    const apiUrl = `https://livechat.discusswerk.com/posts/deletePost`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      if (!response.ok) {
        throw new Error("Failed to delete post");
      }
      await getPostData(); // Re-fetch posts to update the UI
    } catch (error) {
      console.error("Error deleting post:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="feed-parent ">
      <div className="mt-12">
        <Typography  sx={{
    fontSize: {
      xs: '12px',  // 12px for small screens
      sm: '36px',  // 36px for medium and larger screens
    },
  }}
   fontWeight={700}>
          Discuss Werk - Anonymous and Professional Community.
        </Typography>
      </div>
      <div className="input-parent flex gap-10">
        <div className="input-field-width">
          <div
            onClick={() => navigate("/createPost")}
            className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-5 max-h-65px"
            style={{ maxHeight: "65px" }}
          >
            <Link>
              <PlusCircleIcon
                className="h-6 w-6 text-custom"
                aria-hidden="true"
              />
            </Link>
            <input
              style={{
                cursor: "default",
              }}
              onClick={() => navigate("/createPost")}
              type="text"
              placeholder={placeHolder}
              className="flex-1 bg-transparent outline-none"
              aria-label="Search"
            />
            <button type="submit" className="hidden" />
          </div>
          <div className="flex w-full flex-col gap-2 md:gap-4">
            {feedData && feedData.length > 0 ? (
              feedData?.reverse().map((data, index) => (
                <div
                  key={index}
                  className="rounded-xl  mb-2 border border-gray-300 bg-background-surface p-4 flex max-h-[840px] cursor-pointer flex-col gap-4 text-gray-900 hover:shadow-[0_4px_8px_0_rgba(101,105,108,0.10)]"
                  style={{ marginTop: "30px" }}
                  data-testid="article-preview-card"
                  onClick={() => DetailComponentFunction(data)}
                >
                  <div>
                    <div className="flex gap-2">
                      <div className="flex h-10 flex-col gap-1">
                        <div className="flex h-full text-sm font-semibold text-black">
                          <a className="hover:underline">{data.categoryName?? ""}</a>

                          {/* Menu */}

                          {/* <Dot/> */}
                          {/* <span className='text-xs text-gray-600'>
             {data.date}
             </span> */}
                        </div>

                        <div className="flex h-full items-center text-xs text-gray-800">
                          <a className="hover:underline">
                            {data.selectedStateName}
                          </a>
                          <Dot />
                          <span>{data.userNameData}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    class="break-words text-lg font-semibold text-gray-999 self-start"
                    data-testid="article-preview-title"
                  >
                    <h2>{data.title}</h2>
                  </a>
                  {data.description != "0" && (
                    <HtmlContent html={data.description} />
                  )}
                  <div
                    className="relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200"
                    style={{ backgroundSize: "cover" }}
                  >
                    {data.Link && embedYouTubeVideo(data.Link) && (
                      <iframe
                        width="100%"
                        height="315"
                        src={embedYouTubeVideo(data.Link)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    )}
                  </div>
                  {data.Image != "no" && data.Image != "0" && (
                    <>
                      <div
                        className="relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200"
                        style={{
                          height: "632px",
                          background:
                            "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAP0lEQVR4nAE0AMv/AL67wJmfo5Sbpb+uxACnlpWlmJvMsq3/9+gAIx4eJiMlKB0X18S2ADU3MyQlIAEEAJublc/fFx94a7ioAAAAAElFTkSuQmCC)",
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="h-full w-full bg-white/10 backdrop-blur"></div>

                        <img
                          src={`https://livechat.discusswerk.com/${data.Image}`}
                          loading="lazy"
                          decoding="async"
                          data-nimg="fill"
                          class="cursor-pointer object-contain"
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            inset: "0px",
                            color: "transparent",
                          }}
                        />
                      </div>
                      <div className="relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200"></div>
                    </>
                  )}
                  <div className="flex gap-4">
                    {!loading ? (
                      <button
                        onClick={() => updateCount(data.id, "like_count")}
                        aria-label="Like this post"
                        class="flex items-center gap-1 rounded-[20px] bg-background-cloud px-2 text-sm/5 text-gray-800 hover:bg-gray-200"
                      >
                        <View />
                        <span>{data.like_count}</span>
                      </button>
                    ) : (
                      <span>Loading...</span>
                    )}
                    <button
                      aria-label="Comment on this post"
                      onClick={() => DetailComponentFunction(data)}
                      class="flex items-center gap-1 rounded-[20px] bg-background-cloud px-2 text-sm/5 text-gray-800 hover:bg-gray-200"
                    >
                      <Comment />
                      <span>{data.commentCount}</span>
                    </button>
                    <button
                      onClick={() => updateCount(data.id, "view")}
                      aria-label="Like this post"
                      class="flex items-center gap-1 rounded-[20px] bg-background-cloud px-2 text-sm/5 text-gray-800 hover:bg-gray-200"
                    >
                      <Eye />
                      <span>{data.view}</span>
                    </button>
                    {customProp?.type === "user" && (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/createPost", {
                              state: { updateData: data },
                            });
                          }}
                          aria-label="Edit this post"
                          class="flex items-center gap-1 rounded-[20px] bg-background-cloud px-2 text-sm/5 text-gray-800 hover:bg-gray-200"
                        >
                          <AiFillEdit />
                        </button>
                        {deleteLoading ? (
                          <span>Deleting...</span>
                        ) : (
                          <button
                            onClick={(e) => handleDelete(e, data.id)}
                            aria-label="Delete this post"
                            class="flex items-center gap-1 rounded-[20px] bg-background-cloud px-2 text-sm/5 text-gray-800 hover:bg-gray-200"
                          >
                            <AiFillDelete />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <h1 style={{ marginTop: "20px" }}>No Post Found</h1>
            )}
          </div>
        </div>
        {/* <div className='rounded-xl border border-gray-300 bg-background-surface p-4 flex flex-col gap-5  ' style={{ width: '30%',height:"fit-content" }}>
        <h3 className='text-sm font-semibold text-gray-900'>
        Popular Posts
        </h3>
<div>
  <a className='group flex cursor-pointer flex-col gap-2 border-b py-2 first:pt-0 last:border-none'>
    <div className='flex justify-between'>
    <div class="flex gap-2"><p class="text-xs">India</p><p class="text-xs text-gray-600">Yesterday</p></div>
    <div className='flex items-center gap-1'>
<Eye/>
<p className='text-xs text-gray-600'>1820</p>
    </div>
    </div>
    <p class="truncate text-sm font-semibold group-hover:underline self-start" data-testid="popular-article-preview-title">Dating for Marriage is Hard</p>

    </a>  
    <a className='group flex cursor-pointer flex-col gap-2 border-b py-2 first:pt-0 last:border-none'>
    <div className='flex justify-between'>
    <div class="flex gap-2"><p class="text-xs">India</p><p class="text-xs text-gray-600">Yesterday</p></div>
    <div className='flex items-center gap-1'>
<Eye/>
<p className='text-xs text-gray-600'>1820</p>
    </div>
    </div>
    <p class="truncate text-sm font-semibold group-hover:underline self-start" data-testid="popular-article-preview-title">Dating for Marriage is Hard</p>
    </a>  
    <a className='group flex cursor-pointer flex-col gap-2 border-b py-2 first:pt-0 last:border-none'>
    <div className='flex justify-between'>
    <div class="flex gap-2"><p class="text-xs">India</p><p class="text-xs text-gray-600">Yesterday</p></div>
    <div className='flex items-center gap-1'>
<Eye/>
<p className='text-xs text-gray-600'>1820</p>
    </div>
    </div>
    <p class="text-sm font-semibold group-hover:underline self-start" data-testid="popular-article-preview-title">Dating for Marriage is Hard</p>
    </a>  
</div>
      </div> */}
      </div>
      {Detail && <ModalLocation />}
    </div>
  );
};

export default Feed;
