import React from 'react'

const View = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="my-2 h-4 w-4"><path stroke="currentColor" stroke-width="1.4" d="m10.723 4.632.002-.002c.69-.691 1.61-1.1 2.58-1.154l.228-.006a3.958 3.958 0 0 1 2.79 6.758l-6.29 6.29a.044.044 0 0 1-.063 0l-6.29-6.29A3.959 3.959 0 0 1 9.277 4.63l.227.227.493.494.496-.492.229-.227Z" className='my-2 h-4 w-4'></path></svg>
    </div>
  )
}

export default View
