import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import "./index.css";
import SignUp from "./SignUpModal";
import SignIn from "./SignInModal";

const ModalLocation = ({ onClose }) => {
  const [signUp, setSignUp] = useState(false);
  const [signIn, setSignIn] = useState(false);

  const handleSignUp = () => {
    setSignUp((model) => !model);
  };

  const handleSignIn = () => {
    setSignIn((model) => !model);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header flex flex-col gap-2">
          <div className="relative h-10 w-20 flex-shrink-0">
            <img
              src="https://discusswerk.com/assets/discuss-werk-bg-pDwXhT9h.webp"
              alt="Logo"
            />
          </div>
          <Typography fontSize={"32px"} fontWeight={700}>
            Join the community to participate in the discussion
          </Typography>
        </div>
        <div className="modal-body">
          <Typography color={"red"}>Connect with Gig Workers</Typography>
          <div className="modal-actions"></div>
        </div>
        <div className="modal-footer flex-col gap-2">
          <button className="modal-button" onClick={handleSignUp}>
            Sign Up
          </button>
          <button className="modal-button" onClick={handleSignIn}>
            Sign in
          </button>
        </div>
      </div>
      {signUp && (
        <SignUp onCloseSignUp={handleSignUp} handleSignIn={handleSignIn} />
      )}
      {signIn && <SignIn onClose={handleSignIn} />}
    </div>
  );
};

export default ModalLocation;
