import React from 'react'

const Comment = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" className="my-2 h-4 w-4"fill="none" viewBox="0 0 20 20" class="my-2 h-4 w-4"><path stroke="currentColor" stroke-linejoin="round" stroke-width="1.4" d="M4 5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7l-3 3V5Z"></path></svg>
    </div>
  )
}

export default Comment
