import { Typography } from "@mui/material";
import React from "react";
import "./index.css";
const Button = ({ text, onClick }) => {
  return (
    <button className={"button-components"} onClick={onClick} style={{
      padding: "10px 20px",
      borderRadius: "15px",
      cursor: "pointer",
      border: "none",
      outline: "none",
    }} >
      <Typography color="inherit" fontSize={"16px"} fontWeight={500}>
        {text}
      </Typography>
    </button>
  );
};

export default Button;
