import React, { useState, useEffect } from 'react'
import Dot from '../../assets/dot'
import { useLocation } from 'react-router-dom';

const DetailComponent = () => {
  const [commentData, setComent] = useState('');
  const [comments, setComments] = useState([]);

  const [id, setId] = useState('');
  // const [userId,setUserId]=useState("")
  console.log("comments", comments)
  console.log("commentData", commentData)
  const userName = localStorage.getItem('userName'); // Fetch user ID from local storage
  const userId = localStorage.getItem('userId'); // Fetch user ID from local storage

  const location = useLocation();
  const data = location.state;
  console.log("data", data)
  useEffect(() => {
    if (data) {
      setId(data.id);
      // setUserId(data.UserId);
      setComments(data.comments || []);
    }
  }, [data]);

  const updateComment = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const apiUrl = `https://livechat.discusswerk.com/posts/updatePostComment`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, id, commentData, userName })
      });

      if (!response.ok) {
        throw new Error(`Failed to update comment: ${response.statusText}`);
      }

      const newComment = await response.json(); // assuming the server responds with the new comment
      console.log("newComment", newComment)
      setComments([...comments, newComment.comment]); // optimistically update the UI
      setComent('');
      alert('Comment updated successfully!');
    } catch (error) {
      console.error('Error updating comment:', error);
      alert('Error updating comment. Please try again.');
    }
  };
  const HtmlContent = ({ html }) => {
    return (
      <p
        className="whitespace-pre-wrap break-words text-sm/5 [word-break:break-word] self-start"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };
  const embedYouTubeVideo = (link) => {
    const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
    const match = link.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };
  return (
    <div className='flex  w-full justify-center flex-1 '>
      <div className='  px-4 flex h-max flex-col gap-2 py-4  lg:gap-6'>
        <div className='flex w-full flex-col gap-2 md:gap-4'>


          <div className='rounded-xl mb-2 border border-gray-300 bg-background-surface p-4 flex max-h-[840px] cursor-pointer flex-col gap-4 text-gray-900 hover:shadow-[0_4px_8px_0_rgba(101,105,108,0.10)]' style={{ marginTop: '30px', width: 750 }} data-testid="article-preview-card">
            <div>
              <div className='flex gap-2'>
                <div className='flex h-10 flex-col gap-1'>
                  <div className='flex h-full items-center text-sm font-semibold text-black'>
                    <a className='hover:underline'>
                      {data.categoryName}
                    </a>
                    {/* <Dot/> */}
                    {/* <span className='text-xs text-gray-600'>
             {data.date}
             </span> */}
                  </div>
                  <div className='flex h-full items-center text-xs text-gray-800'>
                    <a className='hover:underline'>
                      {data.selectedStateName}
                    </a>
                    <Dot />
                    <span>
                      {data.userNameData}
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <a class="break-words text-lg font-semibold text-gray-999 self-start" data-testid="article-preview-title" ><h2>{data.title}</h2></a>
            {data.description != "0" && <HtmlContent html={data.description} />}
            <div className='relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200' style={{ backgroundSize: "cover" }}>
              {data.Link && embedYouTubeVideo(data.Link) && (
                <iframe
                  width="100%"
                  height="315"
                  src={embedYouTubeVideo(data.Link)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              )}
            </div>
            {data.Image != "no" && data.Image != "0" &&
              <>
                <div className='relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200' style={{ height: "632px", background: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAP0lEQVR4nAE0AMv/AL67wJmfo5Sbpb+uxACnlpWlmJvMsq3/9+gAIx4eJiMlKB0X18S2ADU3MyQlIAEEAJublc/fFx94a7ioAAAAAElFTkSuQmCC)", backgroundSize: "cover" }} >
                  <div className='h-full w-full bg-white/10 backdrop-blur'>

                  </div>

                  <img src={data.image} loading="lazy" decoding="async" data-nimg="fill" class="cursor-pointer object-contain" style={{ position: "absolute", height: "100%", width: "100%", inset: "0px", color: "transparent" }} />
                </div>
                <div className='relative max-h-[420px] w-full rounded-xl border border-gray-300 bg-gray-200'>
                </div>

              </>


            }



          </div>




        </div>
        <div className='-mx-4 mb-4 mt-2 border-t border-gray-300'>

        </div>
        {comments.map((comment) => (
          <div key={comment.id} className="flex flex-col gap-4">
            <div className="w-full gap-1 [scroll-margin-top:100px] flex">
              <div className="flex-1">
                <div className="rounded-xl bg-gray-100 p-3">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-wrap text-xs font-semibold text-gray-700">
                      <p className="font-semibold">
                        {comment.userName}
                      </p>
                    </div>
                  </div>
                  <p className="whitespace-pre-wrap break-words text-sm text-gray-900 md:text-base flex self-start">
                    {comment.comment}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <form className="flex flex-col flex-1 items-start space-y-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-3 relative">
          <textarea
            placeholder="Comment"
            className="flex-1 bg-transparent outline-none rounded-lg border border-gray-200 bg-gray-100 px-3 py-1 resize-none w-full"
            value={commentData}
            onChange={e => setComent(e.target.value)}
            aria-label="Comment"
          ></textarea>

          {/* "Post" button */}
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg  "
            onClick={updateComment}
          >
            Post
          </button>
        </form>
      </div>
    </div>
  )
}

export default DetailComponent
