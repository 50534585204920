import React, { useState } from 'react';
import axios from 'axios';
import { Typography, Snackbar, Alert } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import "./index.css";

const SignUp = ({ onCloseSignUp ,handleSignIn}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Add state for Snackbar severity

  const handleSignUp = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://livechat.discusswerk.com/users/signup', {
        email: email,
        password: password,
        name: userName
      });
      console.log('User created:', response.data);
      setSnackbarMessage('Account successfully created!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => {

        onCloseSignUp(); // Close the modal after a delay
        handleSignIn();
      }, 2000);
    } catch (error) {
      console.error('Error signing up:', error.response ? error.response.data : error.message);
      setSnackbarMessage(error.response ? error.response.data : 'Error signing up. Please try again.'); // Set error message
      setSnackbarSeverity('error');
      setOpenSnackbar(true); // Display the Snackbar with the error message
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header flex flex-col gap-2 w-full">
          <XMarkIcon className="h-6 w-6 cursor-pointer self-end" onClick={onCloseSignUp} aria-label="Close" />
          <div className="relative h-10 w-20 flex-shrink-0">
            <img src="https://discusswerk.com/assets/discuss-werk-bg-pDwXhT9h.webp" alt="Logo" />
          </div>
          <Typography fontSize={"32px"} fontWeight={700}>CREATE YOUR ACCOUNT</Typography>
        </div>
        <div className="modal-body">
          <form className="form-head mt-5 flex flex-col gap-5" onSubmit={handleSignUp}>
            <input type="text" placeholder="Email" className="flex-1 bg-transparent outline-none rounded-lg border border-gray-200 bg-gray-100 px-3 py-1" value={email} onChange={e => setEmail(e.target.value)} aria-label="Email" />
            <input type="password" placeholder="Password" className="flex-1 bg-transparent outline-none rounded-lg border border-gray-200 bg-gray-100 px-3 py-1" value={password} onChange={e => setPassword(e.target.value)} aria-label="Password" />
            <input type="text" placeholder="User Name" className="flex-1 bg-transparent outline-none rounded-lg border border-gray-200 bg-gray-100 px-3 py-1" value={userName} onChange={e => setUserName(e.target.value)} aria-label="User Name" />
            <button type="submit" className="modal-button">Sign Up</button>
          </form>
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default SignUp;
