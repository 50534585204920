import React, { useState } from 'react';
import { Typography, Snackbar, Alert, Button } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import "./index.css";
import { useNavigate, Link } from 'react-router-dom';

const SignIn = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgetPassword, setForgetPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!isForgotPassword) {
      try {
        const response = await axios.post('https://livechat.discusswerk.com/users/login', { email, password });
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem('userId', response.data.user.id);
        localStorage.setItem('userName', response.data.user.userName);
        setSnackbarMessage('Login successful! Welcome back.');
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.href = '/';
          
        }, 2000);
      } catch (err) {
        setError(err.response?.data?.message || 'An error occurred during login');
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
       const user= await axios.post('https://livechat.discusswerk.com/users/request-reset', { email,forgetPassword });
       if(user){
         setSnackbarMessage("Password Updated");
          setOpenSnackbar(true);
          setError("")
          setIsForgotPassword(false); // Reset to login form after request
       }
      } catch (error) {
        setError(error.response?.data?.message );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header flex flex-col gap-2 justify-between items-center w-full">
          <XMarkIcon className="h-6 w-6 cursor-pointer self-end" onClick={onClose} aria-label="Close" />
          <div className="relative h-10 w-20 flex-shrink-0">
            <img src="https://discusswerk.com/assets/discuss-werk-bg-pDwXhT9h.webp" alt="Logo" />
          </div>
          <Typography fontSize={"32px"} fontWeight={700}>Welcome Back!</Typography>
          {error && <Typography color="error">{error}</Typography>}
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit} className='form-head mt-5 flex flex-col gap-5'>
            <div className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-1">
              <input
                type="email"
                placeholder="Email"
                className="flex-1 bg-transparent outline-none"
                aria-label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {!isForgotPassword && (
              <div className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-1">
                <input
                  type="password"
                  placeholder="Password"
                  className="flex-1 bg-transparent outline-none"
                  aria-label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            )}
               {isForgotPassword && (
              <div className="flex flex-1 items-center space-x-2 rounded-lg border border-gray-200 bg-gray-100 px-3 py-1">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="flex-1 bg-transparent outline-none"
                  aria-label="Password"
                  value={forgetPassword}
                  onChange={(e) => setForgetPassword(e.target.value)}
                />
              </div>
            )}
            <div className="flex items-center justify-between">
              <button type="submit" className="modal-button" disabled={isLoading}>
                {isForgotPassword ? 'Reset Password' : (isLoading ? 'Signing In...' : 'Sign In')}
              </button>
              {!isForgotPassword && (
                <Typography onClick={handleForgotPassword} className="modal-link">
                  Forgot Password?
                </Typography>
              )}
             
            </div>
          </form>
        </div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SignIn;
