import React, { useState } from 'react';
import axios from 'axios';
import { Typography, Snackbar, Alert } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await axios.post('https://livechat.discusswerk.com/users/login', { email });
      setSnackbarMessage('Check your email for reset instructions.');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Failed to send reset email.');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <div className="modal">
      <Typography fontSize={"24px"}>Reset Password</Typography>
      <form onSubmit={handleSubmit} className='form-head mt-5 flex flex-col gap-5'>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input"
        />
        <button type="submit" className="button" disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send Reset Email'}
        </button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ForgotPassword;
