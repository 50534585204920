import React from 'react'

const Dot = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" class="text-gray-700"><circle cx="6" cy="7.125" r="1.125" fill="currentColor"></circle></svg>
    </div>
  )
}

export default Dot
